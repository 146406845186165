import { SvgIcon } from "@streamelements/frontend-icons";

export default function DeleteRounded() {
    return (
        <SvgIcon>
            <path d="M18.364 5.63602C16.6641 3.93619 14.404 3 12 3C9.59602 3 7.33592 3.93619 5.63602 5.63602C3.93619 7.33592 3 9.59602 3 12C3 14.404 3.93619 16.6641 5.63602 18.364C7.33592 20.0638 9.59602 21 12 21C14.404 21 16.6641 20.0638 18.364 18.364C20.0638 16.6641 21 14.404 21 12C21 9.59602 20.0638 7.33592 18.364 5.63602ZM12 19.8866C7.65131 19.8866 4.1134 16.3487 4.1134 12C4.1134 7.65131 7.65131 4.1134 12 4.1134C16.3487 4.1134 19.8866 7.65131 19.8866 12C19.8866 16.3487 16.3487 19.8866 12 19.8866Z" />
            <rect x="14.0203" y="8.36345" width="2.28571" height="8" transform="rotate(45 14.0203 8.36345)" />
            <rect x="15.6365" y="14.0203" width="2.28571" height="8" transform="rotate(135 15.6365 14.0203)" />
        </SvgIcon>
    );
}