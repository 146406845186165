import { styled, Modal, Slot, List, TextField, ScrollArea, ListItem } from '@streamelements/frontend-ui';
import { PropsWithChildren, useState } from 'react';
import { Sound } from '../types/Sound';
import { Voice } from '../types/Voice';
import { BlockType } from '../types/Block';

const ModalContent = styled(Modal.Content, {
    display: 'grid',
    gap: '$base',
    padding: 'calc($base * 4)',
});

interface Props {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onItemClick: (item: any) => void;
    items: (Voice | Sound)[],
    type: BlockType
}

export default function SeeMoreModal({ items, open, onOpenChange, ...props }: PropsWithChildren<Props>) {
    const [search, setSearch] = useState<string>();
    const filteredItems = search ? items.filter(item => item.displayName.toLowerCase().includes(search.toLowerCase())) : items;

    return (
        <Modal.Root open={open} onOpenChange={onOpenChange}>
            <Modal.Overlay />
            <Modal.Trigger as={Slot}>
                {props.children}
            </Modal.Trigger>
            <ModalContent>
                <Modal.Close />
                <TextField.Single
                    type='search'
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    label='Search'
                    css={{ width: '100%' }}
                />
                <ScrollArea css={{ maxWidth: 200 }} viewportCss={{ maxHeight: 200 }}>
                    <List css={{ width: '100%' }}>
                        {filteredItems.map(i => (
                            <ListItem css={{ cursor: 'pointer' }} onClick={() => {
                                props.onItemClick(i);
                                setSearch(undefined);
                                onOpenChange(false);
                            }}>
                                {i.displayName}
                            </ListItem>
                        ))}
                    </List>
                </ScrollArea>
            </ModalContent>
        </Modal.Root>
    );
}