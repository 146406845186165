import { PostTTS } from "../types/Request/PostTTS";
import { TTSEffects } from "../types/Response/TTSEffects";

const BASE_URL = 'https://tts-api.streamelements.dev';

const Routes = {
    'GetVoices': `${BASE_URL}/v1/effects`,
    'GeneratePreview': `${BASE_URL}/v1/tts`,
}

type ResponseType = 'json' | 'blob';

function doFetch<T>(url: string, options: RequestInit, responseType: ResponseType = 'json'): Promise<T> {
    return fetch(url, options)
        .then(res => {
            if (res.status !== 200) {
                throw new Error(`${res.status}: ${res.statusText}`);
            }

            switch (responseType) {
                case 'json':
                    return res.json();

                case 'blob':
                    return res.blob();
            }
        })
}

export const getVoices = () => doFetch<TTSEffects>(Routes.GetVoices, { method: 'GET' });
export const generatePreview = (blocks: PostTTS) => doFetch<Blob>(Routes.GeneratePreview, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(blocks) }, 'blob');