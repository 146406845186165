import { Avatar, Badge, Button, darkThemeClass, Divider, FormControlLabel, List, Popover, Select, Slider, styled, Text, Toast } from '@streamelements/frontend-ui';
import { useEffect, useState } from 'react';
import { Effect } from '../types/Effect';
import { Sound } from '../types/Sound';
import { Voice } from '../types/Voice';
import { Block, BlockType } from '../types/Block';
import { generatePreview, getVoices } from '../utils/TTSService';
import PlayRounded from './icons/PlayRounded';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import AddRounded from './icons/AddRounded';
import TextareaAutosize from 'react-textarea-autosize';
import { isEqual } from 'lodash';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import DeleteRounded from './icons/DeleteRounded';
import SettingsRounded from './icons/SettingsRounded';
import LoadingModal from './LoadingModal';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import SeeMoreModal from './SeeMoreModal';

const FeaturedSounds = ['Pajlada_POGGERS', 'NamNamNam', 'discord', 'aaaah', 'amogus'];

const FeaturedVoicesAvatars = {
    'Spongebob': 'https://cdn.streamelements.net/tts/spongebob_avatar.png',
    'Reem': 'https://cdn.streamelements.net/tts/reem_avatar.png',
    'Perry': 'https://cdn.streamelements.net/tts/perry_avatar.png',
    'Yuval': 'https://cdn.streamelements.net/tts/yuval_avatar.png',
    'Hen': 'https://cdn.streamelements.net/tts/hen_avatar.png',
    'Glados': 'https://cdn.streamelements.net/tts/glados_avatar.png',
}

const ExtraVoices = [
    {
        displayName: 'Liel',
        imgUrl: 'https://cdn.streamelements.net/tts/liel_avatar.png',
    },
    {
        displayName: 'Rotem',
        imgUrl: 'https://cdn.streamelements.net/tts/rotem_avatar.png',
    },
    {
        displayName: 'Chris',
        imgUrl: 'https://cdn.streamelements.net/tts/chris_avatar.png',
    },
    {
        displayName: 'Gil',
        imgUrl: 'https://cdn.streamelements.net/tts/gil_avatar.png',
    },
    {
        displayName: 'Niv',
        imgUrl: 'https://cdn.streamelements.net/tts/niv_avatar.png',
    }
]

const getBadgeColorByType = (type: BlockType) => {
    switch (type) {
        default:
        case 'text':
            return 'success';

        case 'sound':
            return 'attention';
    }
}

const Root = styled('div', {
    display: 'grid',
    gridTemplateColumns: '250px 1fr',
    gap: 'calc($base * 2)',
    alignItems: 'start'
});

const CustomCard = styled('div', {
    padding: '$base',
    backgroundColor: '#232428',
    br: '$base'
});

const CustomList = styled(List, {
    display: 'grid',
    gap: '$base'
});

const FeaturedListItem = styled('li', {
    backgroundColor: '#2d2d2d',
    padding: '$base',
    borderRadius: '$base',
    display: 'grid',
    color: '#fff',
    fontFamily: '$fontFamily',
    gridTemplateColumns: 'max-content 2fr max-content max-content',
    gap: '$base',
    alignItems: 'center',
    textAlign: 'start',

    variants: {
        light: {
            true: {
                backgroundColor: '#000000',
            }
        },
        disabled: {
            true: {
                cursor: 'not-allowed',
                filter: 'grayscale(100%)',
                backgroundColor: '#464646'
            }
        }
    }
});

const StyledTimeline = styled('div', {
    backgroundColor: '$uiDisabled25',
    padding: '$base',
    br: '$base',
    display: 'grid',
});

interface TimelineProps {
    blocks: Block[];
    effects: Effect[],
    onDelete: (blockIndex: number) => void,
    onPlay: (block: Block) => void,
    onContentChange: (block: Block, content: string) => void;
    onUpdate: (blockIndex: number, { volume, pitch, effects }: { volume?: number, pitch?: number, effects?: string[] }) => void,
}

const Timeline = SortableContainer(({ blocks, onContentChange, onPlay, onDelete, effects, onUpdate }: TimelineProps) => (
    <StyledTimeline>
        {blocks.map((block, index) => (
            <CustomBlock effects={effects} onUpdate={onUpdate} blockIndex={index} onPlay={onPlay} onDelete={onDelete} onContentChange={onContentChange} block={block} index={index} />
        ))}
    </StyledTimeline>
))

const StyledCustomBlock = styled('div', {
    display: 'grid',
    margin: '$base',
    backgroundColor: '#2d2d2d',
    gridAutoRows: 'max-content',
    padding: '$base',
    borderRadius: '$base',
    cursor: 'grab',
    border: '1px solid $uiDisabled75',

    '&:hover': {
        backgroundColor: '#303030',
    }
});

const CustomBlockHeader = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content',
    gap: '$base',
    alignItems: 'center',
})

interface CustomBlockProps {
    blockIndex: number,
    effects: Effect[],
    block: Block,
    onContentChange: (block: Block, content: string) => void,
    onDelete: (blockIndex: number) => void,
    onPlay: (block: Block) => void,
    onUpdate: (blockIndex: number, { volume, pitch, effects }: { volume?: number, pitch?: number, effects?: string[] }) => void,
}

const TextArea = styled(TextareaAutosize, {
    resize: 'none',
    border: 0,
    color: '#fff',
    backgroundColor: 'transparent',
    fontFamily: '$base',
    outline: 'none'
});

const BlockActions = styled('div', {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gap: 'calc($base * 0.5)',
    alignItems: 'center'
});

const SidebarContainer = styled('aside', {
    display: 'grid',
    gap: 'calc($base * 2)',
    textAlign: 'center',
});

const MainContent = styled(CustomCard, {
    display: 'grid',
    gap: 'calc($base * 2)',
    gridAutoRows: 'max-content',
});

const PopoverContent = styled(Popover.Content, {
    backgroundColor: '#fff',
    color: '#2d2d2d',
    display: 'grid',
    gap: '$base',
});

const PopoverControlItem = styled(FormControlLabel, {
    display: 'grid ',
    gridTemplateColumns: '1fr 2fr'
});

const ButtonsContainer = styled('div', {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '$base',
    justifyContent: 'end',
});

const CustomBlock = SortableElement(({ block, onContentChange, onDelete, onPlay, onUpdate, blockIndex, effects }: CustomBlockProps) => {
    return (
        <StyledCustomBlock className={darkThemeClass}>
            <CustomBlockHeader>
                <Badge color={getBadgeColorByType(block.type)}>{block.type}</Badge>
                <Text.Body variant='caption' weight='bold'>{block.voice || block.content}</Text.Body>
                <BlockActions>
                    {!!block?.effects?.length && (
                        <Badge color='error'>{block.effects[0]}</Badge>
                    )}
                    <Button color='neutral' iconButton variant='ghost' onClick={() => onPlay(block)}>
                        <PlayRounded />
                    </Button>
                    <Button color='neutral' iconButton variant='ghost' onClick={() => onDelete(blockIndex)}>
                        <DeleteRounded />
                    </Button>
                    <Popover.Root>
                        <Popover.Trigger as={Button} color='neutral' iconButton variant='ghost'>
                            <SettingsRounded />
                        </Popover.Trigger>
                        <PopoverContent>
                            <Select
                                label='Effect'
                                options={effects}
                                value={block?.effects?.map(e => effects.find(ef => ef.name === e))}
                                getOptionLabel={(option: Effect) => option.displayName}
                                onChange={(e: Effect) => onUpdate(blockIndex, { effects: [e.name] })}
                            />
                            <PopoverControlItem>
                                Volume
                                <Slider.Root value={block?.volume ? [block?.volume] : [1]} max={1} min={0.5} step={0.05} onValueChange={v => onUpdate(blockIndex, { volume: v[0] })} css={{ width: '100%' }} />
                            </PopoverControlItem>
                            <PopoverControlItem>
                                Pitch
                                <Slider.Root value={block?.pitch ? [block?.pitch] : [1]} max={1.2} min={0.8} step={0.05} onValueChange={v => onUpdate(blockIndex, { pitch: v[0] })} css={{ width: '100%' }} />
                            </PopoverControlItem>
                        </PopoverContent>
                    </Popover.Root>
                </BlockActions>
            </CustomBlockHeader>
            {block.type === 'text' && (
                <>
                    <Divider />
                    <TextArea value={block.content} onChange={(e) => onContentChange(block, e.target.value)} />
                </>
            )}
        </StyledCustomBlock>
    );
});


export default function Editor() {
    const [error, setError] = useState<string>();
    const [seeMoreVoicesModalOpen, setSeeMoreVoicesModalOpen] = useState<boolean>(false);
    const [seeMoreSoundsModalOpen, setSeeMoreSoundsModalOpen] = useState<boolean>(false);
    const [url, setUrl] = useState<string>();
    const [loading, setLoading] = useState<string>();
    const [blocks, setBlocks] = useState<Block[]>([]);
    const [voices, setVoices] = useState<Voice[]>([]);
    const [effects, setEffects] = useState<Effect[]>([]);
    const [sounds, setSounds] = useState<Sound[]>([]);

    const history = useNavigate();
    const [searchParams] = useSearchParams();
    const data = searchParams.get('d');

    const onAddVoiceClicked = (voice: Voice) => {
        setBlocks(prevBlocks => [...prevBlocks, {
            type: 'text',
            content: 'Default Text',
            voice: voice.name,
            voiceID: voice.id,
            volume: 1,
            pitch: 1,
            effects: []
        }]);
    }

    const onAddSoundClicked = (sound: Sound) => {
        setBlocks(prevBlocks => [...prevBlocks, {
            type: 'sound',
            content: sound.name
        }]);
    }

    const handleTimelineSort = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        setBlocks(prevBlocks => arrayMove(prevBlocks, oldIndex, newIndex));
    }

    const onBlockContentChange = (block: Block, content: string) => {
        setBlocks(prevBlocks => prevBlocks.map(b => isEqual(b, block) ? { ...b, content } : b));
    }

    const handleBlockUpdate = (blockIndex: number, { volume, pitch, effects }: { volume?: number, pitch?: number, effects?: string[] }) => {
        setBlocks(prevBlocks => prevBlocks.map((b, i) => isEqual(i, blockIndex) ? { ...b, volume, pitch, effects } : b));
    }

    const generatePreviewFromBlocks = (reqBlocks: Block[]) => {
        setLoading('Generating preview...');

        return generatePreview({ blocks: reqBlocks, channel: '' })
            .then(res => URL.createObjectURL(res))
            .finally(() => setLoading(undefined));
    }

    const generateSinglePreviewClicked = (block: Block) => {
        generatePreviewFromBlocks([block])
            .then(url => {
                const audioElement = document.createElement('audio');
                audioElement.src = url;
                audioElement.play();
            })
    }

    const generatePreviewClicked = () => {
        if (!blocks.length) return;

        const filteredBlocks = blocks.filter(block => !!block.content.trim());

        generatePreviewFromBlocks(filteredBlocks)
            .then(setUrl)
    }

    const downloadButtonClicked = () => {
        if(!url) return;

        let link = document.createElement("a");
        link.download = 'TTS.mp3';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(() => {
        setUrl(undefined);

        if (!blocks.length) {
            history({});
        } else {
            history({ search: `?d=${btoa(JSON.stringify(blocks))}` });
        }
    }, [blocks, history])

    useEffect(() => {
        setError(undefined);
        setLoading('Loading assets...');
        getVoices()
            .then(res => {
                setVoices(res.voices);
                setEffects(res.effects);
                setSounds(res.sounds);
                setLoading(undefined);
            })
            .catch(err => setError('Failed to load assets'));

        if (data) {
            try {
                setBlocks((JSON.parse(atob(data)) as Block[]));
            } catch (e) { }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!!error && (
                <Toast.Root color='error'>
                    <Toast.Text>{error}</Toast.Text>
                </Toast.Root>
            )}

            <Root className={darkThemeClass}>
                {!!loading && <LoadingModal text={loading} />}
                <SidebarContainer>
                    <CustomCard>
                        <Text.Subtitle weight='bold' css={{ color: '#fff' }}>Featured Voices</Text.Subtitle>
                        <CustomList>
                            {voices.filter(v => v.provider.name === 'Custom TTS').map(v => (
                                <FeaturedListItem>
                                    {/** @ts-ignore */}
                                    <Avatar src={FeaturedVoicesAvatars[v.name] ?? 'https://cdn.streamelements.net/tts/wavefile.png'} size='xs' />
                                    <Text.Body weight='bold'>{v.displayName}</Text.Body>
                                    <Button iconButton variant='ghost' color='neutral' onClick={() => generateSinglePreviewClicked({
                                        type: 'text',
                                        content: 'This is a preview',
                                        voice: v.name,
                                        voiceID: v.id
                                    })}>
                                        <PlayRounded />
                                    </Button>
                                    <Button iconButton variant='ghost' color='neutral' onClick={() => onAddVoiceClicked(v)}>
                                        <AddRounded />
                                    </Button>
                                </FeaturedListItem>
                            ))}

                            {ExtraVoices.map(v => (
                                <FeaturedListItem light={true} disabled={true}>
                                    <Avatar src={v.imgUrl} size='xs' />
                                    <Text.Body weight='bold'>{v.displayName}</Text.Body>
                                    <span />
                                    <Text.Body variant='caption'>coming soon</Text.Body>
                                </FeaturedListItem>
                            ))}

                            <FeaturedListItem light={true}>
                                <span />
                                <Text.Body weight='bold'>See more...</Text.Body>
                                <span />
                                <SeeMoreModal open={seeMoreVoicesModalOpen} onOpenChange={setSeeMoreVoicesModalOpen} type='text' items={voices} onItemClick={onAddVoiceClicked}>
                                    <Button iconButton variant='ghost' color='neutral'>
                                        <AddRounded />
                                    </Button>
                                </SeeMoreModal>
                            </FeaturedListItem>
                        </CustomList>
                    </CustomCard>
                    <CustomCard>
                        <Text.Subtitle weight='bold' css={{ color: '#fff' }}>Featured Sounds</Text.Subtitle>
                        <CustomList>
                            {sounds.filter(s => FeaturedSounds.includes(s.name)).map(v => (
                                <FeaturedListItem>
                                    <Avatar src='https://cdn.streamelements.net/tts/wavefile.png' size='xs' />
                                    <Text.Body weight='bold'>{v.displayName}</Text.Body>
                                    <Button iconButton variant='ghost' color='neutral' onClick={() => generateSinglePreviewClicked({
                                        type: 'sound',
                                        content: v.name
                                    })}>
                                        <PlayRounded />
                                    </Button>
                                    <Button iconButton variant='ghost' color='neutral' onClick={() => onAddSoundClicked(v)}>
                                        <AddRounded />
                                    </Button>
                                </FeaturedListItem>
                            ))}

                            <FeaturedListItem light={true}>
                                <span />
                                <Text.Body weight='bold'>See more...</Text.Body>
                                <span />
                                <SeeMoreModal open={seeMoreSoundsModalOpen} onOpenChange={setSeeMoreSoundsModalOpen} type='text' items={sounds} onItemClick={onAddSoundClicked}>
                                    <Button iconButton variant='ghost' color='neutral'>
                                        <AddRounded />
                                    </Button>
                                </SeeMoreModal>
                            </FeaturedListItem>
                        </CustomList>
                    </CustomCard>
                </SidebarContainer>
                <MainContent>

                    {!blocks.length ? (
                        <img src='/images/NoBlocks.svg' alt='No Blocks' style={{ width: 300, margin: '20px auto' }} />
                    ) : (
                        <>
                            <Timeline
                                effects={effects}
                                onDelete={(index) => setBlocks(prevBlocks => prevBlocks.filter((_, i) => i !== index))}
                                onPlay={generateSinglePreviewClicked}
                                lockAxis='y'
                                axis='y'
                                onUpdate={handleBlockUpdate}
                                blocks={blocks}
                                onSortEnd={handleTimelineSort}
                                onContentChange={onBlockContentChange}
                            />
                            {!!url ? (
                                <>
                                    <AudioPlayer
                                        autoPlay
                                        src={url}
                                    />

                                    <Button
                                        color='neutral'
                                        variant='outlined'
                                        onClick={downloadButtonClicked}
                                    >
                                        Download
                                    </Button>
                                </>
                            ) : (
                                <ButtonsContainer>
                                    <Button onClick={() => setBlocks([])} color='neutral' variant='outlined'>
                                        Reset
                                    </Button>
                                    <Button onClick={generatePreviewClicked} color='neutral'>
                                        Generate Preview
                                    </Button>
                                </ButtonsContainer>
                            )}
                        </>
                    )}

                </MainContent>
            </Root >
        </>
    );
}