import { styled } from '@streamelements/frontend-ui';
import Editor from '../components/Editor';

const Root = styled('div', {
    display: 'grid',
    gap: 'calc($base * 2)',
    margin: '0 auto',
    maxWidth: 1038,
});

const Header = styled('div', {
    aspectRatio: '1792/298',
    backgroundImage: 'url(https://cdn.streamelements.net/tts/snakepit.png)',
    backgroundSize: 'contain',
    display: 'grid',
    placeContent: 'center',
    borderRadius: '0 0 $base $base'
});

export default function MainView() {
    return (
        <Root>
            <Header />
            <Editor />
        </Root>
    );
}