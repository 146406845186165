import { styled, Modal, CircularProgress, Text } from '@streamelements/frontend-ui';

const Content = styled(Modal.Content, {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 'calc($base * 6)',
    gap: '$base'
});

export default function LoadingModal({ text = 'Generating...' }: { text?: string }) {
    return (
        <Modal.Root open={true}>
            <Modal.Overlay />
            <Content>
                <CircularProgress.Root variant="indeterminate" size={100} />
                <Text.Subtitle weight='bold'>{text}</Text.Subtitle>
            </Content>
        </Modal.Root>
    );
}